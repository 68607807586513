import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';

import designPro from '../../assets/images/design-pro/design-pro.png';
import designProOne from '../../assets/images/design-pro/design-pro-1.png';
import designProTwo from '../../assets/images/design-pro/design-pro-2.png';
import designProThree from '../../assets/images/design-pro/design-pro-3.png';
import designProClientImage from '../../assets/images/design-pro/design-pro-client.svg';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class DesignPro extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">Using AI to Simplify Task Management</div>
                <div className="subtitle section-margin">
                  Streamlining workflows in Figma and leveraging AI to boost designer productivity
                </div>
                <div className="client-title section-margin">
                  CLIENT | <span style={{color: '#4FBFAD'}}>DESIGN</span>
                  <span style={{color: '#348084'}}>PRO</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Transform their existing Figma plugin for streamlining design workflows by
                      leveraging AI to instantly generate actionable tasks and design feedback.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Explored prompt engineering, chaining and fine-tuning of LLMs (LlaMA-v2,
                      OpenAI’s GPT-4) on data from Figma, Slack and Google Docs to automate task
                      management and design reviews.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      Tintash’s engineering efficiency and AI expertise helped the DesignPro team
                      develop a quick prototyping and research pipeline, allowing the startup to
                      validate their product ideas with real customers.
                    </p>
                  </div>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.loom.com/share/fe481c22091d40a9ab70f90795c37894?sid=b58dcf5c-0613-4956-a5cc-0c47363157de"
                    title="Loom video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="text-with-image-container section-margin">
                    <div className="subheading-big">What was the client looking for?</div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://beta.designpro.ai/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        DesignPro
                      </a>{' '}
                      approached Tintash to help transform their design platform into an innovative
                      AI-driven task generation system. A groundbreaking Figma plugin, DesignPro was
                      created to streamline the way design teams handled tasks. The platform’s
                      creators had recognized a common need among designers for a more efficient and
                      organized workflow within the Figma and Figjam platforms.
                    </p>
                    <br />
                    <p className="description">
                      Designers often grapple with scattered tasks, notes, and communications across
                      various channels. This fragmentation poses a significant hurdle to maintaining
                      a focused and organized design process. While the plugin they had created was
                      able to streamline these workflows, the DesignPro team was now looking to
                      leverage AI to automatically transform those comments, notes, documents,
                      messages and video calls instantly into actionable tasks.
                    </p>
                    <br />
                    <p className="description">
                      By partnering with Tintash, they sought to integrate AI into their existing
                      platform, to efficiently prototype and test these new ideas that would make
                      task organization 6-8 times faster.
                    </p>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      I have worked with some of the best AI talent in the world at companies like
                      Siri and Apple. When it came to building an AI team for my own startup
                      DesignPro, I relied on Tintash to find me talent with excellent research,
                      engineering and communication skills. I am beyond impressed with their talent
                      pool and team’s ability to do exceptional work at an affordable price.
                    </p>
                    <div className="d-flex my-3">
                      <img src={designProClientImage} alt="Mohammad Abdoolcarim" />

                      <div className="p-3 m-0 mt-auto mb-auto">
                        <h2 className="review-name m-0" style={{fontWeight: 'bold'}}>
                          Mohammad Abdoolcarim
                        </h2>
                        <p className="review-name mt-0">Co-Founder, DesignPro</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Our cross-functional team comprising web frontend and backend developers,
                      project managers, and AI engineers managed to rapidly prototype new features
                      and deploy them for quick testing.
                    </p>
                    <br />
                    <br />
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      The Agile Process
                    </p>
                    <p className="description">
                      We followed an agile process where we would explore new product ideas in every
                      sprint. During the initial phase of the project, we would implement a new
                      prototype every other week, test it with real customers, and then quickly move
                      to the next iteration. This process allowed the client to diligently
                      experiment different product ideas with target customer segments using live
                      prototypes and demos.
                    </p>
                    <br />
                    <p className="description">
                      After achieving the initial product-market fit, during the next phase, we
                      developed and deployed polished products in a more scalable and flexible way.
                      Post deployment, we again switched back to the prototyping phase, developing
                      and changing many of the features based on customer feedback and requirements.
                    </p>
                    <br />
                    <br />
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      LLM Integration for Task Synthesis
                    </p>
                    <p className="description">
                      The first feature we developed and deployed was a design workflow management
                      system. Our team adeptly integrated Large Language Models (LLMs) to convert
                      unstructured feedback into structured tasks, akin to Jira.
                    </p>
                    <br />
                    <p className="description">
                      The feature allows designers to import feedback from their clients on the
                      platform. Our solution then analyzes that feedback using LLMs to create a
                      Kanban task board for designers that also gets updated automatically. In
                      addition, it uses AI to figure out task priorities, groupings, labels and
                      tags, assignees and assigners, and other helpful details to enable designers
                      to seamlessly manage their workflows.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="col-lg-6 col-sm-12">
                        <div className="image-description-small text-center my-3">
                          DesignPro is an <span style={{fontWeight: 'bold'}}>AI Product Coach</span>{' '}
                          that synthesizes qualitative data into tasks and insights, from Figma,
                          Slack and Zoom/Loom transcripts.
                        </div>
                        <img className="w-100" src={designProTwo} alt="design pro" />
                      </div>
                      <div className="col-lg-6 col-sm-12 my-4">
                        <img className="w-100" src={designProOne} alt="design pro" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Since a lot of this required the use of generative AI, our team decided that
                      the state of the art LLM offering by OpenAI was best suited to deliver the
                      required results. In order to work efficiently with ChatGPT via the OpenAI
                      API, we utilized Python’s LangChain framework so as to design effective prompt
                      templates.
                    </p>
                    <br />
                    <p className="description">
                      By leveraging this cutting-edge technology, we were able to dramatically
                      reduce task generation time from minutes to seconds. The integration of LLMs
                      impressed clients with its accuracy and speed, resulting in enhanced customer
                      engagement and satisfaction and a rapid growth in DesignPro’s user base.
                    </p>
                    <br />
                    <br />
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      AI-Powered Design Feedback Generation
                    </p>
                    <p className="description">
                      The second major feature we developed for DesignPro was a design reviewer
                      which could autonomously analyze UI designs in Figma and provide actionable
                      feedback. This allows designers to concentrate on creative work while the
                      system automatically generates precise critiques.
                    </p>
                    <br />
                    <p className="description">
                      This solution uses LLMs and AI trained on best-practice frameworks such as
                      Nielsen Norman and IDEO to generate useful feedback for designers. Along with
                      this proprietary dataset of design criteria, we use concepts like prompt
                      chaining and retrieval-based LLMs to get more control over the quality and
                      type of feedback the LLM models generate.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="image-wrapper-3 col-lg-4 col-sm-12">
                        <div className="image-description-small text-center my-3 ">
                          The <span style={{fontWeight: 'bold'}}>AI Product Coach</span> also
                          reviews designs to generate feedback based on design best practices and
                          stakeholder preferences.
                        </div>
                      </div>
                      <div className="col-lg-9 col-sm-12 my-4 ml-auto">
                        <img className="w-100" src={designProThree} alt="design pro" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      This AI feature gained DesignPro a lot of traction with customers and
                      investors alike. Successful demonstrations of our AI's capability to generate
                      precise feedback attracted immediate interest at a designers' conference.
                    </p>
                    <br />
                    <br />
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Extensive and Secure Tool Integration
                    </p>
                    <p className="description">
                      Our dedicated integration team continues to incorporate tools such as Figma,
                      Slack, Zoom, Loom, and Google Docs into DesignPro. This adaptability is key to
                      scaling the product and reaching a broader audience, and has been pivotal in
                      convincing designers to incorporate DesignPro into their workflows. The team
                      ensures a seamless integration of these tools together with robust data
                      security to enhance the user experience.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">What does the final product look like?</div>
                    <p className="description">
                      DesignPro is able to analyze design requirements and feedback and use AI to
                      assist designers manage their workflows. This OpenAI ChatGPT-enabled Figma
                      plugin allows designers to optimize multiple aspects of their design journey:
                    </p>
                    <br />
                    <ul className="description">
                      <li className="mb-2">
                        <span className="latoBold">Streamline workflow:</span> DesignPro can create
                        tasks or use AI to transform comments, notes, docs, Slack threads and video
                        calls into actionable tasks on a Kanban board. These tasks are linked to
                        frames, making it easy to navigate designs.
                      </li>
                      <li className="mb-2">
                        <span className="latoBold">Organize work faster:</span> DesignPro
                        automatically assigns tasks with labels, priorities, frames and
                        collaborators. It applies filters to focus on tasks for specific labels,
                        pages or sections, ensuring that the most important work is finished first.
                      </li>
                      <li className="mb-2">
                        <span className="latoBold">Search designs with ease:</span> DesignPro brings
                        together tasks from multiple data streams, such as Figma, Slack, Google Meet
                        and Google Docs into a central hub users can search. It can also search the
                        design library by label, priority, and date.
                      </li>
                    </ul>
                    <br />
                    <p className="description">
                      The project’s success came down to the productive collaboration with the
                      DesignPro leadership through all stages of the development lifecycle. This
                      included the initial AI roadmap planning and development to designing a
                      user-friendly and intuitive interface for the plugin. Our AI experts stepped
                      in to provide the client with AI thought leadership, LLM fine-tuning and R&D,
                      interfacing with OpenAI services via the LangChain framework, and prompt
                      engineering, all this to go along with our backend development, UI and UX
                      design, project management and quality assurance services.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default DesignPro;
